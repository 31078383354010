/* ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
} */
.center-text {
  text-align: center;
}

.podcastContainer {
  flex: 0 0 33.333333%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ion-label {
  margin-top: 12px;
  margin-bottom: 12px;
}

ion-item h2 {
  font-weight: 600;
  margin: 0;
}

ion-item p {
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
  width: 95%;
}

ion-item ion-icon {
  color: #c9c9ca;
}

ion-item ion-note {
  font-size: 15px;
  margin-right: 8px;
  font-weight: normal;
}

ion-item ion-note.md {
  margin-right: 14px;
}

/* .dot {
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  align-self: start;
  margin: 16px 10px 16px 16px;
} */



ion-footer ion-title {
  font-size: 11px;
  font-weight: normal;
}