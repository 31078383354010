.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.noSelect:focus {
    outline: none !important;
}

:focus {
    outline: none !important;
}

.toolbar-title{
    width: 400px;
}

.podcastMediaControls {

    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

.podcastMedia {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.suggestedPodcasts {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}