#view-message-page ion-item {
  --inner-padding-end: 0;
  --background: transparent;
}

#view-message-page ion-label {
  margin-top: 12px;
  margin-bottom: 12px;
}

#view-message-page ion-item h2 {
  font-weight: 600;
}

.mediaActionButtons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: stretch;
}

#view-message-page ion-item .date {
  float: right;
  align-items: center;
  display: flex;
}

#view-message-page ion-item ion-icon {
  font-size: 42px;
  margin-right: 8px;
}

#view-message-page ion-item ion-note {
  font-size: 15px;
  margin-right: 12px;
  font-weight: normal;
}

#view-message-page h1 {
  margin: 0;
  font-weight: bold;
  font-size: 22px;
}

#view-message-page p {
  line-height: 22px;
}